import React from 'react'
import "./Info.css"

const Info = () => {
  return (
    <div className='info-container'>
      <div className='info'>
        <h1>Jesús le dijo: Yo soy el camino, y la verdad, y la vida; nadie viene al Padre, sino por mí.</h1>
      </div>
    </div>
  )
}

export default Info
