import "./Registrate.css";
//import React, {useState} from 'react'
import { useForm } from '../../hooks/useForm';
import coverVideo from "../../Media/registro.jpg";

export const Registrate = () => {

    const API = process.env.REACT_APP_API;

    const { formulario, email, password, first_name, onChange } = useForm({
        first_name: '',
        email: '',
        password: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(API)
        console.log(formulario)
        const res = await fetch(`${API}/users`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                formulario
            )
        })
        const data = await res.json();
        console.log(data)
    }

  return (

    <body className="img-fondo" style={{ backgroundImage: `url(${coverVideo})` }}>
        <div className="signup-form" >
            <form onSubmit={handleSubmit} >
                <h2>Registro</h2>
                <p className="hint-text">Crea tu cuenta. solo toma un minuto.</p>
                <div className="form-group">
                    <div className="row">
                        <div className="col">
                            <input type="text" 
                            className="form-control"  
                            placeholder="Nombres" 
                            required="required"
                            value={ first_name }
                            onChange={ ({ target }) => onChange( target.value, 'first_name') }
                            />
                        </div>
                        <div className="col">
                            <input type="text" 
                            className="form-control" 
                            name="last_name" 
                            placeholder="Apellidos" 
                            required="required"
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <input type="email" 
                    className="form-control" 
                    placeholder="Email" 
                    required="required"
                    value={ email }
                    onChange={ ({ target }) => onChange( target.value, 'email') }
                    />
                </div>
                <div className="form-group">
                    <input type="password" 
                    className="form-control"  
                    placeholder="Contraseña" 
                    required="required"
                    value={ password }
                    onChange={ ({ target }) => onChange( target.value, 'password') }
                    />
                </div>
                <div className="form-group">
                    <input type="password" 
                    className="form-control" 
                    name="confirm_password" 
                    placeholder="Confirmar Contraseña" 
                    required="required"
                    />
                </div>
                <div className="form-group">
                    <label className="form-check-label">
                        <input type="checkbox" 
                        required="required"
                        /> Acepto el tratamiento de mis datos
                    </label>
                </div>
                <div className="form-group">
                    <button
                    className="btn btn-success btn-lg btn-block">Registrar</button>
                </div>
            </form>
            <div className="text-center">
                <p style={{color: "white"}} 
                className="cuenta1">Ya tienes una cuenta?</p>
                <a href="/login" 
                className="iniciarsesion">&nbsp;Inicia Sesion</a>
            </div>
        </div>
    </body>
  )
}
export default Registrate
