import { Usuario } from '../../interfaces/reqRes';
import { useUsuarios } from '../../hooks/useUsuarios';


export const Usuarios = () => {

    const { usuarios, paginaAnterior, paginaSiguiente } = useUsuarios();


    const renderItem = ({ id, first_name, email, avatar }: Usuario ) => {
        return (
            <tr key={ id }>
                <td>
                    <img 
                        src={ avatar } 
                        alt={ first_name } 
                        style={{
                            width: 35,
                            borderRadius: 100
                        }}
                    />
                </td>
                <td> { first_name  } </td>
                <td> { email }</td>
            </tr>
        )
    }

    return (
        <div className="table-responsive" style={{marginTop: "7%", marginLeft: "2%", marginRight: "2%"}}>
            <h3 className="text-dark" >Usuarios:</h3>
            <table className="table table-success table-striped mt-6px table-hover">
                <thead>
                    <tr className="table-info">
                        <th>Avatar</th>
                        <th>Nombre</th>
                        <th>email</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        usuarios.map( renderItem )
                    }
                </tbody>
            </table>
            

            <button
                className="btn btn-primary"
                onClick={ paginaAnterior }
            >
                Anteriores
            </button>

            &nbsp;

            <button
                className="btn btn-primary"
                onClick={ paginaSiguiente }
            >
                Siguientes
            </button>
        </div>
    )
}

export default Usuarios