import React from 'react'
import "./PalabraDia.css"
import palabradeldia from "../../../src/Media/palabradeldia.jpeg"
import palabradeldia2 from "../../../src/Media/palabradeldia2.jpeg"
import palabradeldia3 from "../../../src/Media/palabradeldia3.jpeg"
import fondopalabra from "../../../src/Media/fondopalabra.jpg"

export const Palabra_dia = () => {
  return (
    <body className="img-fondo" style={{ backgroundImage: `url(${fondopalabra})` }}>
    <div className="conteiner">
        <div className="palabra">
            <h1 className="titulo font-effect-neon" style={{fontWeight: "bold"}}>PALABRA DIARIA</h1>
            <br/>

            <div class="card border-info mb-4" style={{width: "65rem", textAlign: "center"}}>
  <div class="row g-0">
    <div class="col-md-4">
      <img src={palabradeldia3} class="img-fluid rounded-start" alt="..."/>
    </div>
    <div class="col-md-8">
      <div class="card-body">
        {/*<h3 class="card-title" style={{fontWeight: "bold"}}>Dia 5</h3>*/}
        {/*<h5 className="card-title" style={{fontWeight: "bold"}}>En la rivera del Río</h5>*/}
        <p class="card-text text-start">En la rivera del Río de las aguas salutíferas en Ezequiel 47:7 dice que habían muchos árboles al lado y lado. Esto que significa? 
Que aun sumergiéndonos no estamos exentos de pasar por momentos difíciles, por pérdidas, circunstancias, traiciones, desilusiones etc… en el caudal del rio, aún en lo profundo muchas piedras podemos encontrar por muy profundos que estemos. 
Lo que si es cierto es que en el salmo 1 habla del las personas que en medio de estas circunstancias no escuchan los consejos malos y deciden escuchar y caminar como Dios quiere que caminen en medio de los golpes de la vida, dice que serán como árbol plantados junto a corrientes de agua y que dan su fruto a su tiempo, además todo lo que hacen prospera! 
Creo que hoy debes entender que la adversidad no te va Detener, por el contrarío te va a fortalecer, te dará carácter, Y debes saber que la respuesta o la solución (el fruto) llegará en el momento preciso, no antes ni después! Y una vez entiendas lo que implica sumergirse  y confiar en Dios de primero, sin razonar, sólo creyendo, todo lo que hagas, todoooooo prosperará! 
Yo tengo planes de bien para ti te dice el señor… 
Sigue avanzando, yo estoy contigo y nada te va a detener!</p>
      </div>
    </div>
  </div>
</div>



            <div className="card2 border-info mb-4" style={{textAlign: "center"}}>
              <img src={palabradeldia} className="card2-img-top" alt="..."/>
              <div class="card-body2" >
                <br/>
                <br/>
                {/*<h3 class="card-title" style={{fontWeight: "bold"}}>Dia 5</h3>*/}
                {/*<h5 className="card-title" style={{fontWeight: "bold"}}>En la rivera del Río</h5>*/}
                <p className="card-text text-center text-justify" style={{texJustify: "auto"}}>En la rivera del Río de las aguas salutíferas en Ezequiel 47:7 dice que habían muchos árboles al lado y lado. Esto que significa? 
Que aun sumergiéndonos no estamos exentos de pasar por momentos difíciles, por pérdidas, circunstancias, traiciones, desilusiones etc… en el caudal del rio, aún en lo profundo muchas piedras podemos encontrar por muy profundos que estemos. 
Lo que si es cierto es que en el salmo 1 habla del las personas que en medio de estas circunstancias no escuchan los consejos malos y deciden escuchar y caminar como Dios quiere que caminen en medio de los golpes de la vida, dice que serán como árbol plantados junto a corrientes de agua y que dan su fruto a su tiempo, además todo lo que hacen prospera! 
Creo que hoy debes entender que la adversidad no te va Detener, por el contrarío te va a fortalecer, te dará carácter, Y debes saber que la respuesta o la solución (el fruto) llegará en el momento preciso, no antes ni después! Y una vez entiendas lo que implica sumergirse  y confiar en Dios de primero, sin razonar, sólo creyendo, todo lo que hagas, todoooooo prosperará! 
Yo tengo planes de bien para ti te dice el señor… 
Sigue avanzando, yo estoy contigo y nada te va a detener!</p>
  </div>
  <br/>
  <img src={palabradeldia2} className="card2-img-top" alt="..."/>
</div>

        </div>
    </div>
    </body>
    
  )
}
export default Palabra_dia
