import React from "react";
import "./Slider.css";

const slidesInfo = [
    {
        src: "https://i.im.ge/2022/07/24/F7V5u9.jpg",
        alt: "Presentacion 1",
        desc: "Life Music",
    },
    {
        src: "https://i.im.ge/2022/07/24/F7VhPF.jpg",
        alt: "Presentacion 2",
        desc: "Mensaje",
    },
    {
        src: "https://i.im.ge/2022/07/24/F7VifK.jpg",
        alt: "Presentacion 3",
        desc: "Adoracion"
    }
]

const slides = slidesInfo.map(slide => (
    <div className="slide-container">
        <img src={slide.src} alt={slide.alt} />
        <div className="slide-desc">
            <span>{slide.desc}</span>
        </div>
    </div>
))

export default slides;