import LOGOSLIFE from "../../../src/Media/LOGOSLIFE.jpg";
import { useEffect, useReducer } from "react";
import { Redirect } from "react-router-dom";
import { useForm } from '../../hooks/useForm';
import "./Login.css";
//import { useForm } from '../../hooks/useForm';

const initialState = {
  Validando: true,
  token: null,
  username: '',
  nombre: '',
}

const authReducer = ( state, action ) => {

  switch ( action.type ) {
    case 'logout':
      return {
        Validando: false,
        token: null,
        nombre: '',
        username: ''

      }

    case 'login':
      const { nombre, username } = action.payload;
      return {
        Validando: false,
        token: 'ABC123',
        nombre,
        username
      }
  
    default:
      return state;
  }


}



export const Login = () => {

  const [{ Validando, token, nombre }, dispatch ] = useReducer(authReducer, initialState);

  const { formulario, email, password, onChange } = useForm({
    first_name: '',
    email: '',
    password: ''
  });

  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: 'logout'});
    }, 1500);
  }, []);

  const login = () => {
    dispatch({
      type: 'login',
      payload: { 
        nombre: formulario.email,
        username: formulario.password
      }
    })
  }
  
  const logout = () => {
    dispatch({
      type: 'logout'
    });
  }

  if( Validando ) {
    return (
      <>
      <div className="valid-user1">
      <div className="alert alert-info" >
          Validadndo...</div>
      </div>
      </>
    )
  }



  return (
  <div className="formulario">
    <form>
      {
      ( token ) ?
      <>
        <Redirect to="/Usuarios" />
        <div className="alert alert-success">
          Autenticado como: { nombre }
        </div>
      </> : 
      <>
        <div className="alert alert-danger">
          No Autenticado
        </div>
          <img className="mb-4" 
          src={LOGOSLIFE} alt="LIFE" margin-left="200" width="200" height="200"/>
          <h3 className="mt-3 text-dark">Iniciar Sesion</h3>
          <div className="form-floating text-dark">
            <input 
            type="email"
            className="form-control" 
            id="Email"
            value={ email }
            onChange={ ({ target }) => onChange( target.value, 'email') }
            placeholder="name@example.com" />
            <label htmlFor="floatingInput">Correo electrónico</label>
          </div>
          <div 
            className="form-text text-dark p-1"
            id="floatingInput"
            >Nunca compartiremos su correo electrónico con nadie más.</div>
            <div className="form-floating text-dark">
              <input 
              type="password" 
              className="form-control" 
              id="Password"
              value={ password }
              onChange={ ({ target }) => onChange( target.value, 'password') }
              placeholder="Contraseña" />
              <label htmlFor="floatingPassword">Contraseña
              </label>
            </div>
            <div className="mb-1 ms-3 form-check text-dark p-2">
              <input 
              type="checkbox" 
              className="form-check-input" 
              id="Check"
              />
              <label className="form-check-label" 
              htmlFor="Check">Remember me</label>
            </div>
      </>
          }
          {
          ( token )
          ? (
          <button className="btn btn-danger" onClick={ logout }>
            Logout
          </button>
          ) : (
          <>
            <button
              className="w-100 btn btn-lg btn-primary"
              onClick={ login }>
              Login
            </button>
            <div className="text-center2 pt-1" >
              <p style={{height: "8px"}} 
              className="cuenta">No tienes una cuenta</p>
              <a href="/registro" 
              className="registrate">&nbsp;Registrate</a>
            </div>
            <p className="mt-4 mb-3 text-muted">&copy; 2022–2023</p>
          </>
        )

      }
    </form>
  </div>
)}

export default Login
