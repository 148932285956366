import React from 'react';
import "./About.css";
import about from "../../Media/about.JPG";

const About = () => {
  return (
    <div className='about-container'>
      <div className='about-desc'>
        <h3>Te Contaremos Algo Sobre Nosotros</h3>
        <p>Sabes porque cada reunión de Life es una experiencia? Por hay gente apasionada que decide poner sus talentos con excelencia 
        al servicio de Dios…!! Todos los domingos podrás inscribirte para que seas parte del equipo CREW LIFE y puedas servir activamente 
        en nuestras experiencias! #algonuevopasaenlife</p>
      </div>
      <div className='about-img'>
        <img src={about} alt='about' />
      </div>
    </div>
  )
}

export default About