import React from 'react'
import "./Footer.css"

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footer-info'>
            <h1>Colombia</h1>
            <p>Cl. 85 #64-30, Barranquilla, Atlántico</p>
        </div>
        <div className='footer-contact'>
            <h3>Contactanos</h3>
            <p>Josselin Solano +57 300 7357194</p>
        </div>
        <div className='footer-sns'>
        <div className='design-by'>Design By ANGEL MENDOZA</div>
        <div className='sns-links'>
            <a href='https://www.youtube.com/user/danba19' target="_blank" rel="noreferrer">
                <i className='fab fa-youtube youtube'></i>
            </a>
            <a href='https://www.instagram.com/ministeriolifeint/' target="_blank" rel="noreferrer">
                <i className='fab fa-instagram instagram'></i>
            </a>
            <a href='facebook.com' target="_blank" rel="noreferrer">
                <i className='fab fa-facebook facebook'></i>
            </a>
          </div>
        </div>
    </footer>
  )
}

export default Footer
