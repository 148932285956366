import React from 'react';
import "./Navbar.css";

const Navbar = ({ isScrolling }) => {
    /*const toTheTop = () => {
      <a href="/"></a>
    };*/
  return (
    <nav className={`navbar ${isScrolling > 20 ? "scrolling" : "null"}`}>
        <a href="/" className='navbar-logo'>Ministerio life</a>
    </nav>
  );
};

export default Navbar;
