import React from 'react'
import "./Cover.css";
import coverVideo from "../../Media/coverVideo.jpg";
import { NavLink } from "react-router-dom";
import life from "../../Media/life.png";

const Cover = () => {
  return (
    <div className='cover-container'>
        <img className='video' src={coverVideo} alt="sinimagen" />
        <img className='logo' src={life} alt="sinimagen" />
        <h1 className='title'>Bienvenidos</h1>
        <h3 className='bienvenida'>LLegaste a un lugar donde hay vida!</h3>
        <div className="enlace">
        <NavLink activeClassName="active1" to="/">Home |</NavLink>
        <NavLink to="/Login"> Miembro |</NavLink>
        <NavLink to="/Registro"> Registrate |</NavLink>
        <NavLink to="/Noticias"> Noticias |</NavLink>
        <NavLink className="ult-link" to="/Palabra_del_dia"> Palabra del Dia</NavLink>
        </div>
    </div>
  )
}

export default Cover
