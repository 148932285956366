import React, { useState, useEffect } from 'react';
import './App.css';
import Cover from './Components/Cover/Cover';
import Navbar from './Components/navbar/Navbar';
import About from './Components/about/About';
import Slider from './Components/slider/Slider';
import Info from './Components/info/Info';
import Footer from './Components/footer/Footer';
import Login from './Components/Login/Login';
import Noticias from './Components/Noticias/Noticias';
import Registrate from './Components/Registro/Registrate';
import Usuarios from './Components/Usuarios/Usuarios';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Palabra from './Components/Palabra_del_dia/Palabra';
//import Home from './Components/Home/Home';

function App() {
  const [scrollHeight, setScrollHeight] = useState(0)
    
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollHeight(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, [scrollHeight])


  return (
    <div className="App">
      <Navbar isScrolling={scrollHeight} />
      <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Cover />
            <About />
            <Slider />
            <Info />
            <Footer />
            <div>...</div>
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/Usuarios">
            <Usuarios />
          </Route>
          <Route path="/registro">
            <Registrate />
            </Route>
            <Route path="/noticias">
            <Noticias />
          </Route>
          <Route path="/palabra_del_dia">
            <Palabra />
          </Route>
        </Switch>
      </div>
    </Router>
    </div>
  );
}

export default App;
